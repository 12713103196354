import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Book Reading App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/book-reading-mobile-app/"
    metaDescription="Our brand-new design template, Readium, is the perfect solution if you are looking to design your own cutting-edge mobile book reading app."
    description="
    h2:Introducing Readium, our reading app design template for mobile
    <br/>
    Ready to turn the page on complicated design? Want to turn over a new leaf when it comes to design tools? Uizard is here to bring your ideas to life like never before. Our brand-new <a:https://uizard.io/templates/>UI template</a>, Readium, is the perfect solution if you are looking to design your own cutting-edge mobile book reading app.
    <br/>
    h3:Start the next chapter in your design project
    <br/>
    Ideate, prototype, and iterate at lightning speed with Uizard and streamline your project flow. With our smart collaboration features, you can even share and edit your project with internal and external stakeholders in real-time. The faster you prototype, the quicker you can advance onto development. 
    <br/>
    h3:How to make an e-reading app, start with the perfect design template
    <br/>
    Big on customization? Maybe you have different colors or styles in mind? With the power of the Uizard editor, you can use our reading app template straight out of the box, or you can customize every element on the screen to suit your own branding and style. <a:https://uizard.io/prototyping/>UX prototyping</a> has never been so easy.
    "
    pages={[
      "Home and signup screens for new and existing users",
      "A book reading directory showcasing the status of current reads and upcoming books",
      "Personalization screen for custom recommendations",
      "Book market screen with descriptions and a “buy now” call-to-action",
      "Reading interface screen",
      "Book suggestions directory for new discoveries",
    ]}
    projectCode="d9YgqpK4dBs0ZZrnvvGM"
    img1={data.image1.childImageSharp}
    img1alt="book reading app design summary"
    img2={data.image2.childImageSharp}
    img2alt="book reading app design favorites screen"
    img3={data.image3.childImageSharp}
    img3alt="book reading app design book detail screen"
    img4={data.image4.childImageSharp}
    img4alt="book reading app design description screen"
    img5={data.image5.childImageSharp}
    img5alt="book reading app design summary screen"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/book-mobile-app/book-app-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/book-mobile-app/book-app-favorites.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/book-mobile-app/book-app-detailed-view.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/book-mobile-app/book-app-description.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/book-mobile-app/book-app-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
